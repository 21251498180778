<ng-container *transloco="let t">
  @if (isOpen()) {
    <section class="calendar-component">
      <div class="calendar-component__header">
        <div class="calendar-component__header__month-select-container">
          <mzic-svg src="assets/icons/mzic-arrow-left.svg" class="arrow w9" (click)="_lastMonth()" />
          <span>{{ _monthSelected() | monthName: _yearSelected() : currentLanguage() | titlecase }}</span>
          <mzic-svg src="assets/icons/mzic-arrow-right.svg" class="arrow w9" (click)="_nextMonth()" />
        </div>
        <div class="calendar-component__header__year-select-container">
          <mzic-svg src="assets/icons/mzic-arrow-left.svg" class="arrow w9" (click)="_lastYear()" />
          <span>{{ _yearSelected() }}</span>
          <mzic-svg src="assets/icons/mzic-arrow-right.svg" class="arrow w9" (click)="_nextNext()" />
        </div>
      </div>

      @if (quickSelectOptions()) {
        <div class="calendar-component__sub-header" #divToDrag (mousedown)="_mouseDown($event)"
             (mousemove)="_mouseMove($event)" (mouseleave)="_mouseLeave()" (mouseup)="_mouseUp()">
          <div class="calendar-component__sub-header__content">
            <span class="pill"
                  [class.range-selected]="startDateSelected() | pillTodaySelected : endDateSelected()"
                  (click)="_selectTodayOrTomorrow()">{{ t('calendar.today') }}</span>
            <span class="pill"
                  [class.range-selected]="startDateSelected() | pillTomorrowSelected : endDateSelected()"
                  (click)="_selectTodayOrTomorrow(true)">{{ t('calendar.tomorrow') }}</span>
            @if (multiDateSelect()) {
              <span class="pill"
                    [class.range-selected]="startDateSelected() | pillSelected : endDateSelected() : 7"
                    (click)="_setLastsDays(7)">{{ t('calendar.last_7_days') }}</span>
              <span class="pill"
                    [class.range-selected]="startDateSelected() | pillSelected : endDateSelected() : 30"
                    (click)="_setLastsDays(30)">{{ t('calendar.last_30_days') }}</span>
            }
          </div>
        </div>
      }

      <div class="calendar-component__content">
        <div class="calendar-component__content__weekdays date-grid">
          @for (weekday of _weekdays(); track $index) {
            <span>{{ weekday }}</span>
          }
        </div>
        <div class="calendar-component__content__dates date-grid">
          @for (date of _calendarDates(); track $index) {
            <span
              [class.today]="date | isDateToday"
              [class.date-selected]="date | checkDateSelected : multiDateSelect() : dateSelected() : startDateSelected() : endDateSelected()"
              [class.date-between-start-end]="date | isDateBetween : startDateSelected() : endDateSelected()"
              [class.first-date-selected]="multiDateSelect() && (date | dateIsEqual : startDateSelected()) && endDateSelected()"
              [class.end-date-selected]="multiDateSelect() && (date | dateIsEqual : endDateSelected()) && startDateSelected()"
              [class.date-of-another-month]="!(date | isCurrentMonth : _monthSelected())"
              [class.date-previous]="excludeWeekendsAndHolidays() && isPreviousDate(date)"
              [class.date-weekend]="excludeWeekendsAndHolidays() && isWeekend(date)"
              [class.date-holiday]="excludeWeekendsAndHolidays() && isHoliday(date)"
              (click)="_selectDate(date)"
            >
            <span class="content">{{ date.getDate() }}</span>
              @if (multiDateSelect()) {
                <!-- Este @if adiciona um background mais amigável visualmente na data incial e final selecionada
                 quando a seleção de data é multipla -->
                @if (((date | dateIsEqual : startDateSelected()) && endDateSelected()) ||
                ((date | dateIsEqual : endDateSelected()) && startDateSelected())) {
                  <div class="background"></div>
                  <div class="sub-background"></div>
                }
              }
            </span>
          }
        </div>
      </div>

      <div class="calendar-component__content__footer">
        <button class="mz-button secondary small" (click)="cancel()">{{ t('calendar.cancel') }}</button>
        <button
          class="mz-button small"
          [disabled]="multiDateSelect() | isApplyButtonDisabled : dateSelected() : startDateSelected() : endDateSelected()"
          (click)="emitDateSelected()"
        >
          {{ t('calendar.apply') }}
        </button>
      </div>
    </section>
  }
</ng-container>
