import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@mzic/mzic-environments';
import { registerLicense } from '@syncfusion/ej2-base';
import posthog from 'posthog-js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NDaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEBjWH5dcXdQRGFUWUZ+Vg==',
);

posthog.init(environment.posthogKeyProjectMain, {
  api_host: environment.posthogHost,
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
  capture_pageview: false,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
