import { Route } from '@angular/router';
import { redirectAssetGuard } from '@mzic/mzic-guard';
import { AssetComponent } from './pages/asset/asset.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { RedirectPageComponent } from './pages/redirect-page/redirect-Page.component';

export const mzicIoRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  { path: ':lang/home', component: ComingSoonComponent, title: 'MZIC' },
  {
    path: ':lang/assets/:id',
    component: AssetComponent,
    title: 'MZIC',
  },
  {
    path: '**',
    canActivate: [redirectAssetGuard],
    component: RedirectPageComponent,
  },
];
