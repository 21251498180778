import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from 'date-fns';

@Pipe({
  name: 'pillTodaySelected',
  standalone: true,
})
export class PillTodaySelectedPipe implements PipeTransform {
  transform(startDate: Date | null, endDate: Date | null): boolean {
    if (!startDate || !endDate) return false;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return isToday(startDate) && isToday(endDate) && isToday(now);
  }
}
