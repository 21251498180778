@if (data) {
  <section class="simulator-wrapper" id="simulator">
    <h3 class="simulator__title">{{ data.title }}</h3>

    <div class="simulator">
      <div class="range">
        <div class="range__header">
          <div>0%</div>
          <div>100%</div>
        </div>
        <div class="range-container">
          <div class="range-track"></div>
          <div class="range-steps">
            <div
              *ngFor="let step of steps; let i = index"
              class="step"
              (click)="moveHandler($event)"
              [style.left.%]="(i / (steps.length - 1)) * 100"></div>
          </div>
          <div
            class="range-thumb"
            #thumb
            [style.left.%]="position"
            (mousedown)="onDragStart($event)"
            (touchstart)="onDragStart($event)">
            <div
              class="range-thumb__label"
              [class.fisrt]="isFirst"
              [class.second]="isSecond"
              [class.penult]="isPenult"
              [class.last]="isLast">
              <span>{{ tokenCount }}</span>
              tokens
            </div>
            <div>{{ rangeValue }}%</div>
          </div>
        </div>
      </div>
      <aside class="simulator__info">
        <div class="simulator__text" [innerHTML]="text"></div>
        <div class="simulator__total">
          {{ total | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt-BR' }}
        </div>
        <p class="observation">
          {{ data.disclaimer }}
        </p>
      </aside>

      <a [href]="url" rel="noreferrer" target="_blank" class="mz-button full">
        {{ data.urlButtonText }}
      </a>
    </div>
  </section>
}
