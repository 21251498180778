import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { DesktopComponent } from './pages/desktop/desktop.component';
import { Mzic1ContentComponent } from './pages/mobile/content/mzic1/mzic1.component';
import { Mzic2ContentComponent } from './pages/mobile/content/mzic2/mzic2.component';

@Component({
  selector: 'mzic-page-asset',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    DesktopComponent,
    LayoutModule,
    Mzic1ContentComponent,
    Mzic2ContentComponent,
  ],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'asset',
      alias: 'lang',
    }),
  ],
})
export class AssetComponent implements OnInit {
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  breakpointObserver = inject(BreakpointObserver);
  isMobile = false;

  // Armazena os dados dos assets baseado no ID
  data = {
    assetId: '',
    audioUrl: '',
    videoUrl: '',
    qrCodeUrl: '',
  };

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    switch (id) {
      case 'MZIC1':
        this.data.assetId = 'MZIC1';
        this.data.audioUrl =
          '/assets/songs/guilherme-benuto-simone-mendes-manda-um-oi.mp3';
        this.data.videoUrl = '/assets/movies/clip-manda-um-oi.mp4';
        this.data.qrCodeUrl = '/assets/images/main/qr-code-mzic1.png';
        break;
      case 'MZIC2':
        this.data.assetId = 'MZIC2';
        this.data.audioUrl = '/assets/songs/luan-santana-deus-e-muito-bom.mp3';
        this.data.videoUrl = '/assets/movies/clip-deus-e-muito-bom.mp4';
        this.data.qrCodeUrl = '/assets/images/main/qrcode-mzic2.png';
        break;
      default:
        this.router.navigate(['/']);
    }

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
        console.log('Is mobile screen:', this.isMobile);
      });
  }
}
