import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, isSameDay } from 'date-fns';

@Pipe({
  name: 'pillSelected',
  standalone: true,
})
export class PillSelectedPipe implements PipeTransform {
  transform(
    startDate: Date | null,
    endDate: Date | null,
    targetDays: number,
  ): boolean {
    if (!startDate || !endDate) return false;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const daysDiff = differenceInDays(endDate, startDate) + 1;
    const targetDate = new Date(startDate.getTime());

    targetDate.setDate(targetDate.getDate() + targetDays - 1);

    return daysDiff === targetDays && isSameDay(endDate, targetDate);
  }
}
