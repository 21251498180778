import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  model,
  OnInit,
  output,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';

import { isBefore, isEqual, parse, subDays } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';
import { LANGUAGE } from './model/calendar-language.model';

import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import Holidays from 'date-holidays';
import { CalendarOutput } from './model/calendar-output.model';
import { CheckDateSelectedPipe } from './pipes/check-date-selected/check-date-selected.pipe';
import { DateIsEqualPipe } from './pipes/date-is-equal/date-is-equal.pipe';
import { IsApplyButtonDisabledPipe } from './pipes/is-apply-button-disabled/is-apply-button-disabled.pipe';
import { IsCurrentMonthPipe } from './pipes/is-current-month/is-current-month.pipe';
import { IsDateBetweenPipe } from './pipes/is-date-between/is-date-between.pipe';
import { IsDateTodayPipe } from './pipes/is-date-today/is-date-today.pipe';
import { MonthNamePipe } from './pipes/month-name/month-name.pipe';
import { getDataForCalendar, getWeekdaysName } from './util/util';
import { PillSelectedPipe } from './pipes/pill-selected/pill-selected.pipe';
import { PillTodaySelectedPipe } from './pipes/pill-today-selected/pill-today-selected.pipe';
import { PillTomorrowSelectedPipe } from './pipes/pill-tomorrow-selected/pill-tomorrow-selected.pipe';

@Component({
  selector: 'mzic-calendar',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    MonthNamePipe,
    IsDateTodayPipe,
    IsCurrentMonthPipe,
    IsDateBetweenPipe,
    DateIsEqualPipe,
    CheckDateSelectedPipe,
    TranslocoModule,
    IsApplyButtonDisabledPipe,
    PillSelectedPipe,
    PillTodaySelectedPipe,
    PillTomorrowSelectedPipe,
  ],
  providers: [
    DateIsEqualPipe,
    provideTranslocoScope({
      scope: 'mzic-calendar-component',
      alias: 'calendar',
    }),
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements OnInit {
  @ViewChild('divToDrag', { static: false })
  divToDrag!: ElementRef<HTMLDivElement>;

  private translocoService = inject(TranslocoService);

  private _whatDateToSelect: 'START' | 'END' = 'START';
  private _isDragging = false;
  private _startX!: number;
  private _scrollLeft!: number;

  public currentLanguage: WritableSignal<LANGUAGE> = signal(
    this.translocoService.getActiveLang(),
  );

  public isOpen = model.required<boolean>();
  public multiDateSelect = model(false);
  public quickSelectOptions = model(false);
  public ableToClose = model(false);
  public dateOutput = output<CalendarOutput | null>();
  public excludeWeekendsAndHolidays = model(false);

  public startDateSelected: WritableSignal<Date | null> = signal(null);
  public endDateSelected: WritableSignal<Date | null> = signal(null);
  public dateSelected: WritableSignal<Date | null> = signal(null);

  protected _today: Signal<Date> = signal(new Date());
  protected _yearSelected: WritableSignal<number> = signal(
    this._today().getFullYear(),
  );
  protected _monthSelected: WritableSignal<number> = signal(
    this._today().getMonth() + 1,
  );

  protected _weekdays: Signal<Array<string>> = signal(
    getWeekdaysName(this.currentLanguage()),
  );
  protected _calendarDates: WritableSignal<Array<Date> | null> = signal(null);
  protected holiday = new Holidays(
    this.currentLanguage() === 'en' ? 'US' : 'BR',
  );

  ngOnInit() {
    this._getCalendarDates();
  }

  protected _selectDate(date: Date): void {
    if (this.multiDateSelect()) {
      this._selectMultipleDates(date);
      return;
    }

    if (date === this.dateSelected()) {
      this.dateSelected.set(null);
      this.emitNullValueToInput();
    } else {
      this.dateSelected.set(date);
    }
  }

  protected _selectTodayOrTomorrow(tomorrow = false): void {
    let jumpIndex = 0;
    if (tomorrow) jumpIndex = 1;

    this._setCurrentMonthAndYear();
    this._getCalendarDates();

    const dateToSelectIndex = this._findIndexDateInTheList(this._today());

    if (!!dateToSelectIndex && dateToSelectIndex >= 0) {
      if (this.multiDateSelect()) {
        this._selectMultipleDates(
          this._calendarDates()?.[dateToSelectIndex + jumpIndex] as Date,
        );
      } else {
        this.dateSelected.set(
          this._calendarDates()?.[dateToSelectIndex + jumpIndex] ?? null,
        );
      }
    }
  }

  protected _setLastsDays(count: number): void {
    this._setCurrentMonthAndYear();
    this._getCalendarDates();

    const dayToSelect = subDays(this._today(), count - 1);
    this.startDateSelected.set(dayToSelect);
    this._whatDateToSelect = 'END';
    this._selectTodayOrTomorrow();
  }

  protected _lastMonth(): void {
    this._monthSelected.update((month) => month - 1);
    this._getCalendarDates();
  }

  protected _nextMonth(): void {
    this._monthSelected.update((month) => month + 1);
    this._getCalendarDates();
  }

  protected _lastYear(): void {
    this._yearSelected.update((year) => year - 1);
    this._getCalendarDates();
  }

  protected _nextNext(): void {
    this._yearSelected.update((year) => year + 1);
    this._getCalendarDates();
  }

  protected _mouseDown(event: MouseEvent): void {
    this._isDragging = true;
    this._startX = event.pageX - this.divToDrag.nativeElement.offsetLeft;
    this._scrollLeft = this.divToDrag.nativeElement.scrollLeft;
  }

  protected _mouseMove(event: any): void {
    if (!this._isDragging) return;
    event.preventDefault();
    const x = event.pageX - this.divToDrag.nativeElement.offsetLeft;
    const walkX = (x - this._startX) * 1.2;
    this.divToDrag.nativeElement.scrollLeft = this._scrollLeft - walkX;
  }

  protected _mouseLeave(): void {
    this._isDragging = false;
  }

  protected _mouseUp(): void {
    this._isDragging = false;
  }

  private _setCurrentMonthAndYear(): void {
    this._monthSelected.set(this._today().getMonth() + 1);
    this._yearSelected.set(this._today().getFullYear());
  }

  private _getCalendarDates(): void {
    this._calendarDates.set(
      getDataForCalendar(this._yearSelected(), this._monthSelected()),
    );
  }

  private _selectMultipleDates(date: Date): void {
    if (this.startDateSelected() && this.endDateSelected()) {
      this._clearStartAndEndDateSelected();
    }

    if (
      this.startDateSelected() &&
      isBefore(date, this.startDateSelected() as Date)
    ) {
      this._clearStartAndEndDateSelected();
      this._whatDateToSelect = 'START';
    }

    if (this._whatDateToSelect === 'START') {
      this.startDateSelected.set(date);
      this._toggleWhatDateToSelect();
    } else {
      this.endDateSelected.set(date);
      this._toggleWhatDateToSelect();
    }

    if (
      isEqual(this.startDateSelected() as Date, this.endDateSelected() as Date)
    ) {
      // this._clearStartAndEndDateSelected();
    }
  }

  private _clearStartAndEndDateSelected(): void {
    this.startDateSelected.set(null);
    this.endDateSelected.set(null);
    this.emitNullValueToInput();
    this._whatDateToSelect = 'START';
  }

  private _toggleWhatDateToSelect(): void {
    if (this._whatDateToSelect === 'START') {
      this._whatDateToSelect = 'END';
    } else {
      this._whatDateToSelect = 'START';
    }
  }

  private _closeCalendar(): void {
    if (this.ableToClose()) this.isOpen.set(false);
  }

  private _createLocaleDate(value: string): Date {
    const locale = this.currentLanguage();
    const formatDate = locale === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    return parse(value, formatDate, new Date(), {
      locale: locale === 'en' ? enUS : ptBR,
    });
  }

  private _findIndexDateInTheList(date: Date): number | null | undefined {
    return this._calendarDates()?.findIndex((dateToFind) => {
      if (
        dateToFind.getDate() === date.getDate() &&
        dateToFind.getMonth() === date.getMonth() &&
        dateToFind.getFullYear() === date.getFullYear()
      )
        return dateToFind;

      return null;
    });
  }

  isPreviousDate(date: Date): boolean {
    return date < this._today();
  }

  isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  isHoliday(date: Date): boolean {
    return Array.isArray(this.holiday.isHoliday(date));
  }

  public cancel() {
    this._closeCalendar();
  }

  public clearDateSelected(): void {
    this.startDateSelected.set(null);
    this.endDateSelected.set(null);
    this.dateSelected.set(null);
  }

  public emitDateSelected() {
    if (this.multiDateSelect()) {
      if (!(this.startDateSelected() && this.endDateSelected())) return;

      this.dateOutput.emit({
        start: this.startDateSelected() as Date,
        end: this.endDateSelected() as Date,
      });
    } else {
      if (this.dateSelected()) this.dateOutput.emit(this.dateSelected());
    }

    this._closeCalendar();
  }

  public emitNullValueToInput(): void {
    this.dateOutput.emit(null);
  }

  public selectDateBasedOnInput(value: string): void {
    if (this.multiDateSelect()) {
      const dates = value.split(' - ');
      const startDate = this._createLocaleDate(dates[0]);
      const endDate = this._createLocaleDate(dates[1]);

      this.startDateSelected.set(startDate);
      this.endDateSelected.set(endDate);
    } else {
      const date = this._createLocaleDate(value);
      this.dateSelected.set(date);
    }
  }
}
