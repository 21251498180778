import { Interpreter } from './models';

export const interpretersMzic1Data: Interpreter[] = [
  {
    name: 'Guilherme & Benuto',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/6m6e7D2TnV0aYMllFFwMxu?si=o9nZKzL6Q1-4ri9_lBFcow',
        accountName: 'Guilherme & Benuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '9,2M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/@GuilhermeeBenuto',
        accountName: '@GuilhermeBenuto',
        blocks: [
          {
            label: 'Inscritos',
            value: '2,5M',
          },
          {
            label: 'Visualizações',
            value: '2,7B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/guilhermeebenuto/',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,9M',
          },
          {
            label: 'Engajamento',
            value: '0.5%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@guilhermeebenuto',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,1M',
          },
          {
            label: 'Curtidas',
            value: '8M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Haja Colírio – Ao Vivo',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'haja-colirio.jpeg',
        social: [
          {
            value: '303,5M',
            label: 'Streams',
          },
          {
            value: '324M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Duas Três',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'DuasTres.jpeg',
        social: [
          {
            value: '301,7M',
            label: 'Streams',
          },
          {
            value: '72,7M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Assunto Delicado',
        artist: 'Guilherme & Benuto',
        cover: 'ASsuntoDelicado.jpeg',
        social: [
          {
            value: '214,5M',
            label: 'Streams',
          },
          {
            value: '140,3M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: '3 Batidas – Ao Vivo',
        artist: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
        social: [
          {
            value: '188M',
            label: 'Streams',
          },
          {
            value: '178M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Pulei na Piscina – Ao Vivo',
        artist: 'Guilherme & Benuto, DJ Lucas Beat',
        cover: 'puleiNaPiscina.jpeg',
        social: [
          {
            value: '170M',
            label: 'Streams',
          },
          {
            value: '248M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
  {
    name: 'Simone Mendes',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/2eK9gcJQ6uqVvJL63dnOM3?si=1VOBfn-FQfKNXwKI3zj7mQ',
        accountName: 'Simone Mendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '2,3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '10,4M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/channel/UCH7vP1JyEwTy-D-ikZUoCww',
        accountName: '@simonemendesoficial',
        blocks: [
          {
            label: 'Inscritos',
            value: '4,8M',
          },
          {
            label: 'Visualizações',
            value: '1,66B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/simonemendes',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '39,6M',
          },
          {
            label: 'Engajamento',
            value: '0.3%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@simoneses',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '16,6M',
          },
          {
            label: 'Curtidas',
            value: '94M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Erro Gostoso – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'ErroGostoso.jpeg',
        social: [
          {
            value: '368,4M',
            label: 'Streams',
          },
          {
            value: '385M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Daqui pra Sempre',
        artist: 'MANU, Simone Mendes',
        cover: 'DaquiPraSempre.jpeg',
        social: [
          {
            value: '214,9M',
            label: 'Streams',
          },
          {
            value: '412M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Dois Tristes – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'DoisTristes.jpeg',
        social: [
          {
            value: '138,8M',
            label: 'Streams',
          },
          {
            value: '174M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Oceano – Ao Vivo',
        artist: 'Felipe & Rodrigo, Simone Mendes',
        cover: 'Oceano.jpeg',
        social: [
          {
            value: '64,4M',
            label: 'Streams',
          },
          {
            value: '25M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Mulher Foda – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'MulherFoda.jpeg',
        social: [
          {
            value: '61,3M',
            label: 'Streams',
          },
          {
            value: '6M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
];

export const interpretersMzic2Data: Interpreter[] = [
  {
    name: 'Luan Santana',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/3qvcCP2J0fWi0m0uQDUf6r?si=iGunNjoxT1az4_qc3NdDIw',
        accountName: 'Luan Santana',
        blocks: [
          {
            label: 'Seguidores',
            value: '8,5M',
          },
          {
            label: 'Ouvintes mensais',
            value: '11,8M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/channel/UC6rwiIxv0w2fbmmr66wl1rA',
        accountName: '@LuanSantana',
        blocks: [
          {
            label: 'Inscritos',
            value: '12,8M',
          },
          {
            label: 'Visualizações',
            value: '7,1B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/luansantana/',
        accountName: '@luansantana',
        blocks: [
          {
            label: 'Seguidores',
            value: '36,3M',
          },
          {
            label: 'Engajamento',
            value: '0.5%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@luansantana',
        accountName: '@luansantana',
        blocks: [
          {
            label: 'Seguidores',
            value: '5,2M',
          },
          {
            label: 'Curtidas',
            value: '24,9M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'MORENA',
        artist: 'Luan Santana',
        cover: 'morena.jpeg',
        social: [
          {
            value: '226,2M',
            label: 'Streams',
          },
          {
            value: '215,8M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'ABALO EMOCIONAL - Ao Vivo',
        artist: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
        social: [
          {
            value: '204,9M',
            label: 'Streams',
          },
          {
            value: '153,1M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'MEIO TERMO - Ao Vivo',
        artist: 'Luan Santana',
        cover: 'meio-termo.jpeg',
        social: [
          {
            value: '204,5M',
            label: 'Streams',
          },
          {
            value: '72,1M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'ERRO PLANEJADO - Ao Vivo',
        artist: 'Luan Santana, Henrique & Juliano',
        cover: 'erro-planejado.jpeg',
        social: [
          {
            value: '179,1M',
            label: 'Streams',
          },
          {
            value: '106,6M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Namorando Ou Não - Ao Vivo',
        artist: 'Luan Santana, Clayton & Romário',
        cover: 'namorando-ou-nao.jpeg',
        social: [
          {
            value: '169,6M',
            label: 'Streams',
          },
          {
            value: '160,9M',
            label: 'Visualizações',
          },
        ],
      },
    ],
    investimentSimulationSectionData: {
      title: 'Simule seu investimento',
      text: 'Com a posse de <strong>{tokenCount} tokens MZIC2</strong>, nos últimos 12 meses você teria tido um retorno aproximado de:',
      disclaimer:
        'Este valor é baseado em resultados passados e não garante rendimentos futuros.',
      url: 'https://mbstartups.com.br/investir/MZIC2',
      urlButtonText: 'Invista já no Mercado Bitcoin',
      availableTokens: 2000,
      multiplier: 23.822665,
    },
    evolucao_receita: {
      historico_trimestral: [
        {
          trimestre: '2023Q3',
          receita_ecad: {
            valor_total: 0.0,
            porcentagem_total: 0.0,
            breakdown_source: [],
          },
          receita_streaming: {
            valor_total: 1148.8849951547227,
            porcentagem_total: 100.0,
            breakdown_plataforma: [
              {
                plataforma: 'APPLE INC',
                valor: 0.7383014795124985,
                porcentagem: 0.0643,
                quantidade_pagamentos: 2,
              },
              {
                plataforma: 'Amazon Music',
                valor: 0.7031442662023795,
                porcentagem: 0.0612,
                quantidade_pagamentos: 6,
              },
              {
                plataforma: 'Facebook',
                valor: 407.2787375910732,
                porcentagem: 35.4499,
                quantidade_pagamentos: 20,
              },
              {
                plataforma: 'Resso',
                valor: 0.017578606655059486,
                porcentagem: 0.0015,
                quantidade_pagamentos: 1,
              },
              {
                plataforma: 'Spotify',
                valor: 8.174052094602661,
                porcentagem: 0.7115,
                quantidade_pagamentos: 26,
              },
              {
                plataforma: 'TikTok',
                valor: 103.8719867247465,
                porcentagem: 9.0411,
                quantidade_pagamentos: 3,
              },
              {
                plataforma: 'YouTube',
                valor: 628.1011943919304,
                porcentagem: 54.6705,
                quantidade_pagamentos: 11,
              },
            ],
          },
          receita_total: 1148.8849951547227,
        },
        {
          trimestre: '2023Q4',
          receita_ecad: {
            valor_total: 0.0,
            porcentagem_total: 0.0,
            breakdown_source: [],
          },
          receita_streaming: {
            valor_total: 2179.8351182606516,
            porcentagem_total: 100.0,
            breakdown_plataforma: [
              {
                plataforma: 'Amazon Music',
                valor: 0.9492447593732123,
                porcentagem: 0.0435,
                quantidade_pagamentos: 11,
              },
              {
                plataforma: 'Deezer',
                valor: 2.5137407516735064,
                porcentagem: 0.1153,
                quantidade_pagamentos: 17,
              },
              {
                plataforma: 'Facebook',
                valor: 183.23939577234012,
                porcentagem: 8.4061,
                quantidade_pagamentos: 7,
              },
              {
                plataforma: 'Resso',
                valor: 0.5273581996517845,
                porcentagem: 0.0242,
                quantidade_pagamentos: 6,
              },
              {
                plataforma: 'Spotify',
                valor: 6.662291922267545,
                porcentagem: 0.3056,
                quantidade_pagamentos: 23,
              },
              {
                plataforma: 'TikTok',
                valor: 69.75191120727604,
                porcentagem: 3.1999,
                quantidade_pagamentos: 4,
              },
              {
                plataforma: 'YouTube',
                valor: 1916.1911756480692,
                porcentagem: 87.9053,
                quantidade_pagamentos: 21,
              },
            ],
          },
          receita_total: 2179.8351182606516,
        },
        {
          trimestre: '2024Q1',
          receita_ecad: {
            valor_total: 8020.820000000001,
            porcentagem_total: 58.4849,
            breakdown_source: [
              {
                source: 'ALTERNATIVO TV GLOBO',
                valor: 0.18,
                porcentagem: 0.0022,
                quantidade_pagamentos: 1,
              },
              {
                source: 'ALTERNATIVO TV RECORD',
                valor: 5.69,
                porcentagem: 0.0709,
                quantidade_pagamentos: 5,
              },
              {
                source: 'ALTERNATIVO TV SBT',
                valor: 6.85,
                porcentagem: 0.0854,
                quantidade_pagamentos: 4,
              },
              {
                source: 'APPLE MUSIC',
                valor: 43.82,
                porcentagem: 0.5463,
                quantidade_pagamentos: 1,
              },
              {
                source: 'CASAS DE FESTAS E DIVERSÃO',
                valor: 49.16,
                porcentagem: 0.6129,
                quantidade_pagamentos: 1,
              },
              {
                source: 'FACEBOOK',
                valor: 451.42,
                porcentagem: 5.6281,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING CENTRO OESTE',
                valor: 6.4,
                porcentagem: 0.0798,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORDESTE',
                valor: 23.01,
                porcentagem: 0.2869,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORTE',
                valor: 15.28,
                porcentagem: 0.1905,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING SUDESTE',
                valor: 95.3,
                porcentagem: 1.1882,
                quantidade_pagamentos: 3,
              },
              {
                source: 'INTERNET SIMULCASTING SUL',
                valor: 63.29,
                porcentagem: 0.7891,
                quantidade_pagamentos: 1,
              },
              {
                source: 'MÚSICA AO VIVO',
                valor: 39.86,
                porcentagem: 0.497,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS CENTRO OESTE + DIREITOS',
                valor: 220.31,
                porcentagem: 2.7467,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORDESTE + DIREITOS GERAIS',
                valor: 537.68,
                porcentagem: 6.7036,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORTE + DIREITOS GERAIS',
                valor: 379.1,
                porcentagem: 4.7264,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS SUDESTE + DIREITOS GERAIS',
                valor: 3022.8500000000004,
                porcentagem: 37.6875,
                quantidade_pagamentos: 3,
              },
              {
                source: 'RÁDIOS SUL + DIREITOS GERAIS',
                valor: 1546.53,
                porcentagem: 19.2814,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SHOW',
                valor: 733.85,
                porcentagem: 9.1493,
                quantidade_pagamentos: 11,
              },
              {
                source: 'SHOW - FESTA JUNINA',
                valor: 37.68,
                porcentagem: 0.4698,
                quantidade_pagamentos: 2,
              },
              {
                source: 'SPOTIFY BRASIL FREE',
                valor: 34.92,
                porcentagem: 0.4354,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SPOTIFY BRASIL PREMIUM',
                valor: 392.32,
                porcentagem: 4.8913,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TIKTOK FREE',
                valor: 102.71,
                porcentagem: 1.2805,
                quantidade_pagamentos: 2,
              },
              {
                source: 'TV GLOBO + DIREITOS GERAIS',
                valor: 0.81,
                porcentagem: 0.0101,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TV RECORD + DIREITOS GERAIS',
                valor: 15.569999999999999,
                porcentagem: 0.1941,
                quantidade_pagamentos: 5,
              },
              {
                source: 'TV SBT + DIREITOS GERAIS',
                valor: 16.400000000000002,
                porcentagem: 0.2045,
                quantidade_pagamentos: 4,
              },
              {
                source: 'YOUTUBE',
                valor: 179.82999999999998,
                porcentagem: 2.242,
                quantidade_pagamentos: 4,
              },
            ],
          },
          receita_streaming: {
            valor_total: 5693.517330900561,
            porcentagem_total: 41.5151,
            breakdown_plataforma: [
              {
                plataforma: 'Spotify',
                valor: 4222.311004118668,
                porcentagem: 74.16,
                quantidade_pagamentos: 44,
              },
              {
                plataforma: 'TikTok',
                valor: 334.2747841526112,
                porcentagem: 5.8711,
                quantidade_pagamentos: 4,
              },
              {
                plataforma: 'YouTube',
                valor: 1136.9315426292824,
                porcentagem: 19.9689,
                quantidade_pagamentos: 25,
              },
            ],
          },
          receita_total: 13714.337330900562,
        },
        {
          trimestre: '2024Q2',
          receita_ecad: {
            valor_total: 6497.449999999999,
            porcentagem_total: 40.3327,
            breakdown_source: [
              {
                source: 'ALTERNATIVO TV RECORD',
                valor: 0.8,
                porcentagem: 0.0123,
                quantidade_pagamentos: 1,
              },
              {
                source: 'ALTERNATIVO TV RECORD NEWS',
                valor: 0.09,
                porcentagem: 0.0014,
                quantidade_pagamentos: 1,
              },
              {
                source: 'APPLE MUSIC',
                valor: 25.78,
                porcentagem: 0.3968,
                quantidade_pagamentos: 1,
              },
              {
                source: 'DEEZER MUSIC BRASIL',
                valor: 82.53,
                porcentagem: 1.2702,
                quantidade_pagamentos: 1,
              },
              {
                source: 'DEEZER MUSIC BRASIL FREE',
                valor: 2.01,
                porcentagem: 0.0309,
                quantidade_pagamentos: 1,
              },
              {
                source: 'FACEBOOK',
                valor: 77.52,
                porcentagem: 1.1931,
                quantidade_pagamentos: 1,
              },
              {
                source: 'GLOBOPLAY',
                valor: 1.03,
                porcentagem: 0.0159,
                quantidade_pagamentos: 8,
              },
              {
                source: 'INTERNET SIMULCASTING CENTRO OESTE',
                valor: 14.2,
                porcentagem: 0.2185,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORDESTE',
                valor: 20.94,
                porcentagem: 0.3223,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORTE',
                valor: 8.39,
                porcentagem: 0.1291,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING SUDESTE',
                valor: 72.33,
                porcentagem: 1.1132,
                quantidade_pagamentos: 2,
              },
              {
                source: 'INTERNET SIMULCASTING SUL',
                valor: 27.470000000000002,
                porcentagem: 0.4228,
                quantidade_pagamentos: 2,
              },
              {
                source: 'NAPSTER BRAZIL',
                valor: 1.45,
                porcentagem: 0.0223,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RESSO FREE',
                valor: 5.11,
                porcentagem: 0.0786,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS CENTRO OESTE + DIREITOS',
                valor: 392.63,
                porcentagem: 6.0428,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORDESTE + DIREITOS GERAIS',
                valor: 547.05,
                porcentagem: 8.4195,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORTE + DIREITOS GERAIS',
                valor: 170.5,
                porcentagem: 2.6241,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS SUDESTE + DIREITOS GERAIS',
                valor: 2326.63,
                porcentagem: 35.8084,
                quantidade_pagamentos: 2,
              },
              {
                source: 'RÁDIOS SUL + DIREITOS GERAIS',
                valor: 665.9300000000001,
                porcentagem: 10.2491,
                quantidade_pagamentos: 2,
              },
              {
                source: 'SHOW',
                valor: 1318.92,
                porcentagem: 20.299,
                quantidade_pagamentos: 11,
              },
              {
                source: 'SHOW CARNAVAL',
                valor: 205.17,
                porcentagem: 3.1577,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SONORIZAÇÃO AMBIENTAL',
                valor: 81.17,
                porcentagem: 1.2493,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SPOTIFY BRASIL FREE',
                valor: 18.44,
                porcentagem: 0.2838,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SPOTIFY BRASIL PREMIUM',
                valor: 276.63,
                porcentagem: 4.2575,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TIKTOK FREE',
                valor: 41.42,
                porcentagem: 0.6375,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TV RECORD + DIREITOS GERAIS',
                valor: 2.57,
                porcentagem: 0.0396,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TV RECORD NEWS + DIREITOS GERAIS',
                valor: 0.08,
                porcentagem: 0.0012,
                quantidade_pagamentos: 1,
              },
              {
                source: 'YOUTUBE',
                valor: 110.66,
                porcentagem: 1.7031,
                quantidade_pagamentos: 2,
              },
            ],
          },
          receita_streaming: {
            valor_total: 9612.19306226639,
            porcentagem_total: 59.6673,
            breakdown_plataforma: [
              {
                plataforma: 'APPLE INC',
                valor: 500.3750384362682,
                porcentagem: 5.2056,
                quantidade_pagamentos: 35,
              },
              {
                plataforma: 'Amazon Music',
                valor: 2877.5475950066175,
                porcentagem: 29.9364,
                quantidade_pagamentos: 113,
              },
              {
                plataforma: 'Spotify',
                valor: 1480.9976106887616,
                porcentagem: 15.4075,
                quantidade_pagamentos: 162,
              },
              {
                plataforma: 'TikTok',
                valor: 272.11683102032083,
                porcentagem: 2.831,
                quantidade_pagamentos: 21,
              },
              {
                plataforma: 'YouTube',
                valor: 4115.520968689182,
                porcentagem: 42.8156,
                quantidade_pagamentos: 133,
              },
              {
                plataforma: 'iMusica',
                valor: 365.63501842523726,
                porcentagem: 3.8039,
                quantidade_pagamentos: 14,
              },
            ],
          },
          receita_total: 16109.643062266388,
        },
        {
          trimestre: '2024Q3',
          receita_ecad: {
            valor_total: 6030.59,
            porcentagem_total: 52.9217,
            breakdown_source: [
              {
                source: 'ALTERNATIVO TV BAND',
                valor: 50.16,
                porcentagem: 0.8318,
                quantidade_pagamentos: 2,
              },
              {
                source: 'ALTERNATIVO TV RECORD',
                valor: 0.74,
                porcentagem: 0.0123,
                quantidade_pagamentos: 2,
              },
              {
                source: 'APPLE MUSIC',
                valor: 23.24,
                porcentagem: 0.3854,
                quantidade_pagamentos: 1,
              },
              {
                source: 'CASAS DE FESTAS E DIVERSÃO',
                valor: 57.62,
                porcentagem: 0.9555,
                quantidade_pagamentos: 1,
              },
              {
                source: 'DEEZER MUSIC BRASIL',
                valor: 100.53,
                porcentagem: 1.667,
                quantidade_pagamentos: 1,
              },
              {
                source: 'DEEZER MUSIC BRASIL FREE',
                valor: 1.57,
                porcentagem: 0.026,
                quantidade_pagamentos: 1,
              },
              {
                source: 'FACEBOOK',
                valor: 48.96,
                porcentagem: 0.8119,
                quantidade_pagamentos: 1,
              },
              {
                source: 'GLOBOPLAY',
                valor: 0.04,
                porcentagem: 0.0007,
                quantidade_pagamentos: 2,
              },
              {
                source: 'GRUPO MÚSICA',
                valor: 12.19,
                porcentagem: 0.2021,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING CENTRO OESTE',
                valor: 6.41,
                porcentagem: 0.1063,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORDESTE',
                valor: 15.19,
                porcentagem: 0.2519,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING NORTE',
                valor: 5.14,
                porcentagem: 0.0852,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING SUDESTE',
                valor: 54.910000000000004,
                porcentagem: 0.9105,
                quantidade_pagamentos: 2,
              },
              {
                source: 'INTERNET SIMULCASTING SUL',
                valor: 21.77,
                porcentagem: 0.361,
                quantidade_pagamentos: 1,
              },
              {
                source: 'NAPSTER BRAZIL',
                valor: 1.18,
                porcentagem: 0.0196,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RESSO FREE',
                valor: 1.0,
                porcentagem: 0.0166,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS CENTRO OESTE + DIREITOS',
                valor: 173.09,
                porcentagem: 2.8702,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORDESTE + DIREITOS GERAIS',
                valor: 363.2,
                porcentagem: 6.0226,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS NORTE + DIREITOS GERAIS',
                valor: 128.1,
                porcentagem: 2.1242,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS SUDESTE + DIREITOS GERAIS',
                valor: 1603.99,
                porcentagem: 26.5976,
                quantidade_pagamentos: 2,
              },
              {
                source: 'RÁDIOS SUL + DIREITOS GERAIS',
                valor: 539.5,
                porcentagem: 8.9461,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SHOW',
                valor: 2123.67,
                porcentagem: 35.215,
                quantidade_pagamentos: 23,
              },
              {
                source: 'SHOW - FESTA JUNINA',
                valor: 210.34,
                porcentagem: 3.4879,
                quantidade_pagamentos: 3,
              },
              {
                source: 'SONORIZAÇÃO AMBIENTAL',
                valor: 82.24,
                porcentagem: 1.3637,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SPOTIFY BRASIL FREE',
                valor: 16.77,
                porcentagem: 0.2781,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SPOTIFY BRASIL PREMIUM',
                valor: 278.27,
                porcentagem: 4.6143,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TIKTOK FREE',
                valor: 34.09,
                porcentagem: 0.5653,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TV RECORD + DIREITOS GERAIS',
                valor: 1.9400000000000002,
                porcentagem: 0.0322,
                quantidade_pagamentos: 2,
              },
              {
                source: 'TV SBT + DIREITOS GERAIS',
                valor: 0.01,
                porcentagem: 0.0002,
                quantidade_pagamentos: 1,
              },
              {
                source: 'YOUTUBE',
                valor: 74.73,
                porcentagem: 1.2392,
                quantidade_pagamentos: 2,
              },
            ],
          },
          receita_streaming: {
            valor_total: 5364.709493417674,
            porcentagem_total: 47.0783,
            breakdown_plataforma: [
              {
                plataforma: 'APPLE INC',
                valor: 58.30823827483231,
                porcentagem: 1.0869,
                quantidade_pagamentos: 11,
              },
              {
                plataforma: 'Amazon Music',
                valor: 137.710804535736,
                porcentagem: 2.567,
                quantidade_pagamentos: 45,
              },
              {
                plataforma: 'Deezer',
                valor: 112.96012636541225,
                porcentagem: 2.1056,
                quantidade_pagamentos: 32,
              },
              {
                plataforma: 'Facebook',
                valor: 869.086313026141,
                porcentagem: 16.2001,
                quantidade_pagamentos: 29,
              },
              {
                plataforma: 'Spotify',
                valor: 1393.8604574996318,
                porcentagem: 25.982,
                quantidade_pagamentos: 102,
              },
              {
                plataforma: 'TikTok',
                valor: 609.0811419911562,
                porcentagem: 11.3535,
                quantidade_pagamentos: 10,
              },
              {
                plataforma: 'YouTube',
                valor: 2183.7024117247647,
                porcentagem: 40.705,
                quantidade_pagamentos: 124,
              },
            ],
          },
          receita_total: 11395.299493417675,
        },
        {
          trimestre: '2024Q4',
          receita_ecad: {
            valor_total: 3097.3299999999995,
            porcentagem_total: 100.0,
            breakdown_source: [
              {
                source: 'CASAS DE FESTAS E DIVERSÃO',
                valor: 54.15,
                porcentagem: 1.7483,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING CENTRO OESTE',
                valor: 3.75,
                porcentagem: 0.1211,
                quantidade_pagamentos: 2,
              },
              {
                source: 'INTERNET SIMULCASTING NORDESTE',
                valor: 10.82,
                porcentagem: 0.3493,
                quantidade_pagamentos: 2,
              },
              {
                source: 'INTERNET SIMULCASTING NORTE',
                valor: 1.29,
                porcentagem: 0.0416,
                quantidade_pagamentos: 1,
              },
              {
                source: 'INTERNET SIMULCASTING SUDESTE',
                valor: 43.379999999999995,
                porcentagem: 1.4006,
                quantidade_pagamentos: 3,
              },
              {
                source: 'INTERNET SIMULCASTING SUL',
                valor: 30.150000000000002,
                porcentagem: 0.9734,
                quantidade_pagamentos: 2,
              },
              {
                source: 'RÁDIOS CENTRO OESTE + DIREITOS',
                valor: 77.56,
                porcentagem: 2.5041,
                quantidade_pagamentos: 2,
              },
              {
                source: 'RÁDIOS NORDESTE + DIREITOS GERAIS',
                valor: 311.09000000000003,
                porcentagem: 10.0438,
                quantidade_pagamentos: 2,
              },
              {
                source: 'RÁDIOS NORTE + DIREITOS GERAIS',
                valor: 23.46,
                porcentagem: 0.7574,
                quantidade_pagamentos: 1,
              },
              {
                source: 'RÁDIOS SUDESTE + DIREITOS GERAIS',
                valor: 1241.3,
                porcentagem: 40.0765,
                quantidade_pagamentos: 3,
              },
              {
                source: 'RÁDIOS SUL + DIREITOS GERAIS',
                valor: 771.11,
                porcentagem: 24.896,
                quantidade_pagamentos: 2,
              },
              {
                source: 'SHOW',
                valor: 171.66,
                porcentagem: 5.5422,
                quantidade_pagamentos: 4,
              },
              {
                source: 'SHOW - FESTA JUNINA',
                valor: 160.55,
                porcentagem: 5.1835,
                quantidade_pagamentos: 1,
              },
              {
                source: 'SONORIZAÇÃO AMBIENTAL',
                valor: 164.98,
                porcentagem: 5.3265,
                quantidade_pagamentos: 1,
              },
              {
                source: 'TV GLOBO + DIREITOS GERAIS',
                valor: 0.8200000000000001,
                porcentagem: 0.0265,
                quantidade_pagamentos: 7,
              },
              {
                source: 'TV RECORD + DIREITOS GERAIS',
                valor: 0.08,
                porcentagem: 0.0026,
                quantidade_pagamentos: 2,
              },
              {
                source: 'TV SBT + DIREITOS GERAIS',
                valor: 31.18,
                porcentagem: 1.0067,
                quantidade_pagamentos: 2,
              },
            ],
          },
          receita_streaming: {
            valor_total: 0.0,
            porcentagem_total: 0.0,
            breakdown_plataforma: [],
          },
          receita_total: 3097.3299999999995,
        },
      ],
    },
  },
];
