<div class="container">
  <header class="container__header">
    <button (click)="closeModal()" class="mz-button mini only-icon link">
      <mzic-svg class="w24" src="assets/icons/mzic-close.svg"></mzic-svg>
    </button>
  </header>
  <h1 class="container__title">Fontes de receita</h1>

  <div class="content">
    <ul>
      @for (item of list; track $index) {
        <li class="item">
          <p class="source">{{ item.source }}</p>
          <p class="value">
            {{ item.value | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt-BR' }}
          </p>
          <p class="percentage">{{ item.percentage }}%</p>
        </li>
      } @empty {
        Sem fontes de receita.
      }
    </ul>
  </div>
</div>
