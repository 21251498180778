export interface Composer {
  id: string;
  name: string;
  bio: string[];
  compositions: Compose[];
}

export interface Compose {
  music: string;
  artistsName: string;
  cover: string;
}

export interface InterpreterMusic {
  name: string;
  artist: string;
  cover: string;
  social: {
    value: string;
    label: string;
  }[];
}

export interface Interpreter {
  name?: string;
  social?: {
    title: string;
    accountLink: string;
    accountName: string;
    blocks: {
      label: string;
      value: string;
    }[];
  }[];
  musics?: InterpreterMusic[];
  // está em PT BR, por estar em conformidade com o script de extração do felipe
  evolucao_receita?: RevenueData;
  investimentSimulationSectionData?: InvestimentSimulationSectionData;
}

export interface InvestimentSimulationSectionData {
  title: string;
  availableTokens: number;
  multiplier: number;
  text: string;
  disclaimer: string;
  url: string;
  urlButtonText: string;
}

export interface SourceBreakdown {
  source: string;
  valor: number;
  porcentagem: number;
  quantidade_pagamentos: number;
}

export interface RevenueData {
  historico_trimestral: QuarterData[];
}

export interface QuarterData {
  trimestre: string;
  receita_ecad: ReceitaECAD;
  receita_streaming: ReceitaStreaming;
  receita_total: number;
}

export interface ReceitaStreaming {
  valor_total: number;
  porcentagem_total: number;
  breakdown_plataforma: PlatformBreakdown[];
}

export interface ReceitaECAD {
  valor_total: number;
  porcentagem_total: number;
  breakdown_source: SourceBreakdown[];
}

export interface PlatformBreakdown {
  plataforma: string;
  valor: number;
  porcentagem: number;
  quantidade_pagamentos: number;
}

export class RevenueDataUI {
  accumulated: {
    total: number;
    ecad: {
      total: number;
      percentage: number;
      sources: {
        name: string;
        value: number;
        percentage: number;
      }[];
    };
    streaming: {
      total: number;
      percentage: number;
      plataforms: {
        name: string;
        value: number;
        percentage: number;
      }[];
    };
  };
  quarterly: {
    quarter: string;
    total: number;
  }[];

  constructor() {
    this.accumulated = {
      total: 0,
      ecad: {
        total: 0,
        percentage: 0,
        sources: [],
      },
      streaming: {
        total: 0,
        percentage: 0,
        plataforms: [],
      },
    };
    this.quarterly = [];
  }
}
