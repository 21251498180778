import { CommonModule, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  Renderer2,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InvestimentSimulationSectionData } from '../../../../../data/models';

@Component({
  selector: 'mzic-investment-simulation-section',
  standalone: true,
  imports: [CommonModule],
  providers: [DecimalPipe],
  templateUrl: './investment-simulation-section.component.html',
  styleUrl: './investment-simulation-section.component.scss',
})
export class MzicInvestmentSimulationSectionComponent
  implements OnInit, AfterViewInit
{
  @Input() data!: InvestimentSimulationSectionData | undefined;
  @ViewChild('thumb', { static: false }) thumb!: ElementRef;

  numberPipe = inject(DecimalPipe);

  tokensAvailable!: number;
  multiplier!: number;

  tokens!: number;
  steps = Array(13).fill(0); // 13 steps
  stepCount = this.steps.length - 1; // 12 intervalos
  isDragging = false;
  position = (1 / this.stepCount) * 100; // Posição inicial (0 to 100)

  protected url: string | null = '';
  text: string | null | undefined = '';

  get rangeValue() {
    const count = (this.position / 100) * 100;
    return this.numberPipe.transform(count, '1.0-2', 'pt-BR');
  }

  get total() {
    return this.tokens * this.multiplier;
  }

  get isFirst() {
    return this.position === 0;
  }

  get isSecond() {
    const step = Math.round((this.position / 100) * this.stepCount);
    return step === 1;
  }

  get isPenult() {
    const step = Math.round((this.position / 100) * this.stepCount);
    return step === 11;
  }

  get isLast() {
    return this.position === 100;
  }

  get tokenCount() {
    // se o número for inteiro, não formata
    if (this.tokens % 1 === 0) {
      return this.numberPipe.transform(this.tokens, '1.0-2', 'pt-BR');
    }

    return this.numberPipe.transform(this.tokens, '1.2-2', 'pt-BR');
  }

  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.tokensAvailable = this.data.availableTokens;
      this.multiplier = this.data.multiplier;

      this.tokens = (this.tokensAvailable / 12) * 1;

      this.url = this.sanitizer.sanitize(SecurityContext.URL, this.data.url);
      this.text = this.sanitizer.sanitize(SecurityContext.HTML, this.data.text);

      const token = this.tokenCount;
      if (token) {
        this.text = this.text?.replace('{tokenCount}', token);
      }
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen(
      this.thumb.nativeElement,
      'mousemove',
      this.moveHandler,
    );
    this.renderer.listen(
      this.thumb.nativeElement,
      'touchmove',
      this.moveHandler,
    );
    this.renderer.listen(this.thumb.nativeElement, 'mouseup', this.stopHandler);
    this.renderer.listen(
      this.thumb.nativeElement,
      'touchend',
      this.stopHandler,
    );
  }

  stopHandler = () => {
    this.isDragging = false;
    this.renderer.listen(this.thumb.nativeElement, 'mousemove', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'touchmove', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'mouseup', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'touchend', () => false);
  };

  moveHandler = (moveEvent: MouseEvent | TouchEvent) => {
    const clientX =
      'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rect = (
      this.thumb.nativeElement as HTMLElement
    ).parentElement!.getBoundingClientRect();

    // Calculate new position
    const newLeft = Math.min(Math.max(0, clientX - rect.left), rect.width);
    const percentage = (newLeft / rect.width) * 100;

    // Snap to the nearest step
    const stepValue = Math.round((percentage / 100) * this.stepCount);
    this.position = (stepValue / this.stepCount) * 100;

    const step = Math.round((this.position / 100) * this.stepCount);
    this.tokens = (this.tokensAvailable / 12) * step;
  };

  onDragStart(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    this.isDragging = true;
  }
}
