import {
  ConnectedPosition,
  Overlay,
  OverlayModule,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  CommonModule,
  DecimalPipe,
  registerLocaleData,
  ViewportScroller,
} from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MzicSvgComponent } from '@mzic/mzic-components';
import { environment } from '@mzic/mzic-environments';
import { posthog } from 'posthog-js';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { composersMzic1Data } from '../../../../../../data/composers';
import { interpretersMzic1Data } from '../../../../../../data/interpreters';
import { Composer, Interpreter } from '../../../../../../data/models';
import { LyricsMzic1Component } from '../../../../lyrics/mzic1/mzic1.component';

registerLocaleData(ptBr);

@Component({
  selector: 'mzic-content-mzic1',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    FormsModule,
    OverlayModule,
    RouterModule,
  ],
  providers: [DecimalPipe],
  templateUrl: './mzic1.component.html',
  styleUrl: './mzic1.component.scss',
})
export class Mzic1ContentComponent implements OnInit, AfterViewInit {
  // Simulador
  tokensAvailable = 5000;
  multiplier = 228.318406;
  @ViewChild('thumb') thumb!: ElementRef;
  tokens = (this.tokensAvailable / 12) * 1;
  steps = Array(13).fill(0); // 13 steps
  stepCount = this.steps.length - 1; // 12 intervalos
  isDragging = false;
  position = (1 / this.stepCount) * 100; // Posição inicial (0 to 100)

  // Conteúdo dinâmico
  composersName: string[] = [];
  composers = composersMzic1Data;
  interpreters = interpretersMzic1Data;
  composer!: Composer;
  interpreter!: Interpreter;
  interpreterSelected = 0;
  composerSelected = 0;
  tabProjectionSelected = 1;
  tabPrincipalSelected = 'top';

  mainUrl = environment.mainUrl;
  legalUrl = environment.legalUrl;

  // Player
  isPlaying = false;
  composerCoverUrl = '/assets/images/main/covers/';

  currentTemplate!: TemplateRef<any>;
  renderer = inject(Renderer2);

  @ViewChild('videoPlayer') videoPlayer!: ElementRef;

  // ViewChild para os templates
  @ViewChild('spotify') spotifyTemplate!: TemplateRef<any>;
  @ViewChild('youtube') youtubeTemplate!: TemplateRef<any>;
  @ViewChild('instagram') instagramTemplate!: TemplateRef<any>;
  @ViewChild('tiktok') tiktokTemplate!: TemplateRef<any>;

  expandDetailsCard = true;
  expandPerformanceCard = true;
  expandInterpreterCard = true;

  viewportScroller = inject(ViewportScroller);
  numberPipe = inject(DecimalPipe);
  dialog = inject(MatDialog);

  currentProgress = 0;
  progressCircleDashArray = 2 * Math.PI * 45; // Circunferência do círculo
  progressCircleDashOffset = this.progressCircleDashArray;

  tooltipOpen = false;
  private overlayRef!: OverlayRef;
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);

  get rangeValue() {
    const count = (this.position / 100) * 100;
    return this.numberPipe.transform(count, '1.0-2', 'pt-BR');
  }

  get total() {
    return this.tokens * this.multiplier;
  }

  get isFirst() {
    return this.position === 0;
  }

  get isSecond() {
    const step = Math.round((this.position / 100) * this.stepCount);
    return step === 1;
  }

  get isPenult() {
    const step = Math.round((this.position / 100) * this.stepCount);
    return step === 11;
  }

  get isLast() {
    return this.position === 100;
  }

  get tokenCount() {
    // se o número for inteiro, não formata
    if (this.tokens % 1 === 0) {
      return this.numberPipe.transform(this.tokens, '1.0-2', 'pt-BR');
    }

    return this.numberPipe.transform(this.tokens, '1.2-2', 'pt-BR');
  }

  ngOnInit(): void {
    this.composersName = this.composers.map((item: Composer) => item.name);
    this.composer = this.composers[0];
    this.interpreter = this.interpreters[0];

    // Instancia o componente Swiper
    new Swiper('.swiper', {
      modules: [Navigation],
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 24,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.muted = true;
    video.play();

    this.setupIntersectionObserver();

    // Attach event listeners
    this.renderer.listen(
      this.thumb?.nativeElement,
      'mousemove',
      this.moveHandler,
    );
    this.renderer.listen(
      this.thumb?.nativeElement,
      'touchmove',
      this.moveHandler,
    );
    this.renderer.listen(
      this.thumb?.nativeElement,
      'mouseup',
      this.stopHandler,
    );
    this.renderer.listen(
      this.thumb?.nativeElement,
      'touchend',
      this.stopHandler,
    );
  }

  setupIntersectionObserver() {
    const sectionTop = document.querySelector('#top');
    const sectionArtist = document.querySelector('#artists');
    const sectionSimulator = document.querySelector('#investiment');
    const options = {
      root: null, // Usa o viewport como referência
      rootMargin: '0px',
      threshold: [0.1, 0.1], // Define que 50% da seção precisa estar visível
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.tabPrincipalSelected = entry.target.id; // Atualiza a aba ativa
        }
      });
    }, options);

    if (sectionTop) {
      observer.observe(sectionTop);
    }

    if (sectionArtist) {
      observer.observe(sectionArtist);
    }

    if (sectionSimulator) {
      observer.observe(sectionSimulator);
    }
  }

  playMusic(audio: HTMLAudioElement): void {
    if (this.isPlaying) {
      this.stopMusic(audio);
      return;
    }

    audio.play();
    this.isPlaying = true;
    this.updateProgress(audio);

    posthog.capture('play_button_clicked', { button_name: 'Play' });

    // Detecta quando o áudio termina
    audio.onended = () => {
      this.isPlaying = false;
    };
  }

  stopMusic(audio: HTMLAudioElement): void {
    audio.pause();
    this.isPlaying = false;

    posthog.capture('stop_button_clicked', { button_name: 'Stop' });
  }

  updateProgress(audio: HTMLAudioElement) {
    const update = () => {
      this.currentProgress = (audio.currentTime / audio.duration) * 100;
      this.progressCircleDashOffset =
        this.progressCircleDashArray -
        (this.currentProgress / 100) * this.progressCircleDashArray;
      if (!audio.paused) {
        requestAnimationFrame(update);
      }
    };
    update();
  }

  tabComposer(index: number) {
    this.composerSelected = index;
    this.composer = this.composers[index];
  }

  tabInterpreter(index: number) {
    this.interpreterSelected = index;
    this.interpreter = this.interpreters[index];
  }

  tabProjection(index: number) {
    this.tabProjectionSelected = index;
  }

  getSocialTemplateByIndex(index: number) {
    if (index === 0) {
      return this.spotifyTemplate;
    }

    return this.youtubeTemplate;
  }

  getSocialTemplate(title: string) {
    switch (title) {
      case 'Spotify':
        return this.spotifyTemplate;
      case 'Youtube':
        return this.youtubeTemplate;
      case 'Instagram':
        return this.instagramTemplate;
      case 'TikTok':
        return this.tiktokTemplate;
      default:
        return this.spotifyTemplate;
    }
  }

  scrollToSection(sectionId: string): void {
    this.tabPrincipalSelected = sectionId;

    // Caso especial para o topo
    if (sectionId === 'top') {
      this.viewportScroller.scrollToPosition([0, 0]);
      return;
    }

    const element = document.getElementById(sectionId);
    // Considera o tamanho do header
    const headerOffset = 162;
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      this.viewportScroller.scrollToPosition([0, offsetPosition]);
    }
  }

  openLyrics() {
    this.dialog.open(LyricsMzic1Component, {
      maxWidth: '342px',
      panelClass: 'lyrics-dialog',
    });
  }

  toggleTooltip(
    trigger: HTMLElement,
    tooltipTemplate: TemplateRef<any>,
    right = false,
  ) {
    if (this.tooltipOpen) {
      this.overlayRef?.detach();
      this.tooltipOpen = false;
    } else {
      let position: ConnectedPosition;
      if (right) {
        position = {
          originX: 'center',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: 24,
          offsetY: -12,
        };
      } else {
        position = {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -12,
        };
      }

      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(trigger)
        .withPositions([position])
        .withViewportMargin(5) // Margem para evitar ultrapassar a viewport
        .withPush(true); // Desativa empurrar para dentro da viewport

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.block(), // Bloqueia scroll
        hasBackdrop: true,
      });

      this.overlayRef.backdropClick().subscribe(() => {
        this.closeTooltip();
      });

      this.overlayRef.attach(
        new TemplatePortal(tooltipTemplate, this.viewContainerRef),
      );
      this.tooltipOpen = true;
    }
  }

  closeTooltip() {
    this.overlayRef?.detach();
    this.tooltipOpen = false;
  }

  onDragStart(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  moveHandler = (moveEvent: MouseEvent | TouchEvent) => {
    const clientX =
      'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;

    const rect = (
      this.thumb.nativeElement as HTMLElement
    ).parentElement!.getBoundingClientRect();

    // Calculate new position
    const newLeft = Math.min(Math.max(0, clientX - rect.left), rect.width);
    const percentage = (newLeft / rect.width) * 100;

    // Snap to the nearest step
    const stepValue = Math.round((percentage / 100) * this.stepCount);
    this.position = (stepValue / this.stepCount) * 100;

    const step = Math.round((this.position / 100) * this.stepCount);
    this.tokens = (this.tokensAvailable / 12) * step;
  };

  stopHandler = () => {
    this.isDragging = false;
    this.renderer.listen(this.thumb.nativeElement, 'mousemove', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'touchmove', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'mouseup', () => false);
    this.renderer.listen(this.thumb.nativeElement, 'touchend', () => false);
  };
}
