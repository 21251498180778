import { Pipe, PipeTransform } from '@angular/core';
import { isTomorrow } from 'date-fns';

@Pipe({
  name: 'pillTomorrowSelected',
  standalone: true,
})
export class PillTomorrowSelectedPipe implements PipeTransform {
  transform(startDate: Date | null, endDate: Date | null): boolean {
    if (!startDate || !endDate) return false;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    return isTomorrow(startDate) && isTomorrow(endDate) && isTomorrow(tomorrow);
  }
}
