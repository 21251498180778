import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-revenue-source',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  providers: [DecimalPipe],
  templateUrl: './revenue-source.component.html',
  styleUrl: './revenue-source.component.scss',
})
export class RevenueSourceMzicComponent {
  dialog = inject(MatDialog);
  list: { source: string; value: number; percentage: number }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { source: string; value: number; percentage: number }[],
  ) {
    this.list = data;
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
